.order-container{
  width: 100%;
  height: calc(100vh - 100px);
}

.order-sub-container{
  width: 100%;
  height: 50px;
  box-shadow: 0 1px 2px 0 #272727;
  display: flex;

}

.users-list{
  height: inherit;
  width: 700px;  
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom{
  font-family: var(--family);
  border: none;
  background: var(--header);
  height: inherit;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 500;

}

.console{
  display: flex;
  justify-content: center;
}

.custom:hover{
 border-bottom: 2px solid var(--brand-color);
 color: var(--brand-color);
}

.activeButton{
  border-bottom: 2px solid var(--brand-color);
  color: var(--brand-color);
}

.order-list{
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create{
  margin-right: 20px;
}

.ant-modal-content{
  background-color: var(--header) !important;
  color: white !important;
}

.anticon svg{
  fill: white !important;
}

.order-form-container{
  width: 450px;
  height: 370px;
  margin-top: 30px;
  overflow-y: scroll;
}

.order-form-container2{
  width: 450px;
  height: 100px;
  margin-top: 30px;
  overflow-y: scroll;
}

.ant-form-item-required{
  color: white !important;
}

.ant-input-number-handler-wrap{
  display: none;
}

.ant-modal-title{
  background-color: var(--header) !important;
  color: white !important;
}

.table .ant-table-thead > tr > th {
  line-height: 1.5; /* Adjusts the header text spacing */
  height: 48px; /* Set a fixed height for headers */
}

.table .ant-table-cell {
  white-space: nowrap; /* Prevents cell content from wrapping */
}


.order-header{
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 50px;
}

.console-chart-container{
  width: 100%;
  height: calc(100vh - 100px);
}

.nt-pl{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  box-shadow: 0 1px 2px 0 #272727;
  margin-bottom: 10px;
}